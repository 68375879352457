// ProtectedRoute.js
import { useEffect, useState } from 'react';
import { getBaseUrl, getRole } from '../utils/helper';
import { Loader } from '../components/common/Loader';
import { useNavigate } from 'react-router-dom';

export const ProtectedRoute = ({ element, allowedRoles }) => {

  const [loading , setLoading] = useState(false);
  const navigate = useNavigate();
  const getRoleFromUrl =()=> {
    // Get the current URL
    const url = window.location.href;
    
    // Create a URLSearchParams object to work with query parameters
    const urlParams = new URLSearchParams(new URL(url).search);
    
    // Get the 'role' query parameter
    const role = urlParams.get('role');
    
    return role;  // Return the role (or null if not found)
  }

  const getTokenFromUrl =()=> {
    // Get the current URL
    const url = window.location.href;
    
    // Create a URLSearchParams object to work with query parameters
    const urlParams = new URLSearchParams(new URL(url).search);
    
    // Get the 'role' query parameter
    const token = urlParams.get('token');
    
    return token;  // Return the role (or null if not found)
  }

  const getCountryFromUrl =()=> {
    // Get the current URL
    const url = window.location.href;
    
    // Create a URLSearchParams object to work with query parameters
    const urlParams = new URLSearchParams(new URL(url).search);
    
    // Get the 'role' query parameter
    const country = urlParams.get('country');
    
    return country;  // Return the role (or null if not found)
  }

  const getRequestIdFromUrl =()=> {
    // Get the current URL
    const url = window.location.href;
    
    // Create a URLSearchParams object to work with query parameters
    const urlParams = new URLSearchParams(new URL(url).search);
    
    // Get the 'role' query parameter
    const requestId = urlParams.get('requestId');
    
    return requestId;  // Return the role (or null if not found)
  }

  const role = getRoleFromUrl() || getRole();
  const token = getTokenFromUrl() || sessionStorage.getItem('token');
  const country = getCountryFromUrl() || sessionStorage.getItem('country');
  const requestId = getRequestIdFromUrl();

  const getCustomerGeneralSettings = async (role, token) => {
    try {
          const baseUrl = getBaseUrl(country);
    const url = country === 'UAE' ? `${baseUrl}/governify/${role}/governifySiteSetting` : `${baseUrl}/governify/${role}/governifySiteSetting`

      const response = await fetch( url, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const data = await response.json();
      return data;
    } catch (err) {
      console.log(err, 'error');
    }
  };
  
  const getLoginUserDetails = async(token) =>{
    const baseUrl = getBaseUrl(country);
    const url = country === 'UAE' ? `${baseUrl}/loginUserDetails/${token}` : `${baseUrl}/loginUserDetails/${token}`
    try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
         return data;
      } catch (err) {
          console.log(err , 'error');
      }
  }

  const fetchApiData = async () => {
    try {
      setLoading(true);
      const response = await getCustomerGeneralSettings(role , token);
      const response1 = await getLoginUserDetails(token);

      if(response.status){
        sessionStorage.setItem(
          "settings",
          response.response.ui_settings
        );
        sessionStorage.setItem(
          "logo_location",
          response.response.logo_location
        );
      }
      if (response1.success) {
        sessionStorage.setItem("userEmail", response1.data.email);
        sessionStorage.setItem("userName", response1.data.name);
        sessionStorage.setItem("userId", response1.data.user_id);
        sessionStorage.setItem("phone", response1.data.phone);
        if(window.location.href.includes('role')){
          removeQueryParams();
          // window.location.reload();
        }
      }

    } catch (err) {
     console.log(err , 'error');  // If an error occurs, store the error message
    } finally {
      setLoading(false);  // Always stop loading when done
    }
  };


  const fetchApiDataForTrackPage = async () => {
    try {
      setLoading(true);
      const response = await getCustomerGeneralSettings(role , token);
      const response1 = await getLoginUserDetails(token);

      if(response.status){
        sessionStorage.setItem(
          "settings",
          response.response.ui_settings
        );
        sessionStorage.setItem(
          "logo_location",
          response.response.logo_location
        );
      }
      if (response1.success) {
        sessionStorage.setItem("userEmail", response1.data.email);
        sessionStorage.setItem("userName", response1.data.name);
        sessionStorage.setItem("userId", response1.data.user_id);
        sessionStorage.setItem("phone", response1.data.phone);
        if(window.location.href.includes('role')){
          removeQueryParams();
          window.location.reload();
        }
        sessionStorage.setItem("requestId", requestId);
        sessionStorage.setItem("modalOpen", true);

        navigate(`track-request`);

      }

    } catch (err) {
     console.log(err , 'error');  // If an error occurs, store the error message
    } finally {
      setLoading(false);  // Always stop loading when done

    }
  };

  const removeQueryParams = () => {
    // Remove the query params from the URL (role and token)
    const url = new URL(window.location.href);
    url.searchParams.delete('role');
    url.searchParams.delete('token');
    url.searchParams.delete('country');
    url.searchParams.delete('path');
    

    // Update the URL without reloading the page
    window.history.pushState({}, '', url.toString());
  };


 useEffect(()=>{

  if(requestId !== null){

    if(role !== null && token !== null && country !== null){
      sessionStorage.setItem('token' , token);
      sessionStorage.setItem('role' , role);
      sessionStorage.setItem('country' , country);
      sessionStorage.setItem('requestId' , requestId);
      fetchApiDataForTrackPage();
    }
  }else{
    if(role !== null && token !== null && country !== null){
      sessionStorage.setItem('token' , token);
      sessionStorage.setItem('role' , role);
      sessionStorage.setItem('country' , country);
      fetchApiData();
    }
  }
   
  

  if(role === null){
    window.location.href = 'https://governify.tasc360.com';
  }

 } ,[role]);


 if (loading) {
  return <Loader />;
}

  return element;
};

